
/* !
 * jQuery products v1.0.0
 * Copyright 2019, Connecty Inc.
 */
(function($, DUNLOP){
	const mapSite = DUNLOP.va.pathname.split('/')[1];
	const mapCat = DUNLOP.va.pathname.split('/')[3];
	let mapId;
	if (DUNLOP.va.pathname.split('/')[6]) {
		mapId = DUNLOP.va.pathname.split('/')[6].replace(/\.html/, '');
	} else if (DUNLOP.va.pathname.split('/')[5]) {
		mapId = DUNLOP.va.pathname.split('/')[5].replace(/\.html/, '');
	} else if (DUNLOP.va.pathname.split('/')[4]) {
		mapId = DUNLOP.va.pathname.split('/')[4].replace(/\.html/, '');
	}
	if (mapSite === 'exhibition') {
		mapId = DUNLOP.va.pathname.split('/')[3].replace(/\.html/, '');
	}

	// ＜JSON＞製品カテゴリー取得
	const getJSONCategory = () => {
		return $.getJSON(`/${mapSite}/products/ajax/category.json`);
	};
	const getJSONCategoryAll = () => {
		return $.getJSON(`/${mapSite}/products/ajax/category_all.json`);
	};
	// ＜JSON＞製品ブランド取得
	const getJSONBrand = () => {
		return $.getJSON(`/${mapSite}/products/ajax/brand.json`);
	};
	// ＜JSON＞製品情報取得
	const getJSONProdDetail = (_id) => {
		return $.getJSON(`/${mapSite}/products/ajax/detail/${_id}.json`);
	};
	// ＜共通＞取得したJSONデータを_keyの昇順で並べ替える
	const sortKey = (_optionArr) => {
		for (let i=0; i<_optionArr.length; i++) {
			_optionArr[i]._key = ('0000' + _optionArr[i]._key).slice(-4);
		}
		// _keyで昇順ソート
		_optionArr.sort((a, b) => {
			if (a._key < b._key) return(-1);
			if (a._key > b._key) return(1);
			return 0;
		});
		return _optionArr;
	};
	// ＜商品検索結果＞フォームに検索した値を反映
	const setSearchFormQueryStr = (_$ele, _name) => {
		if (location.search) {
			const searchArr = location.search.slice(1).split('&');
			for (let i=0; i<searchArr.length; i++) {
				if (searchArr[i].split('=')[0] === _name) {

					if (searchArr[i].split('=')[1].indexOf('+') !== -1 || searchArr[i].split('=')[1].indexOf('%20') !== -1) {
						// カテゴリー連結検索の場合 +を半角スペースに変換
						_$ele.val(searchArr[i].split('=')[1].replace(/\+/g, ' ').replace(/%20/g, ' '));
					} else {
						_$ele.val(searchArr[i].split('=')[1]);
					}
				}
			}
		}
	};
	// ＜商品検索＞カテゴリー選択
	const setSelectCategory = () => {
		const appendCategoryOption = (_optionArr) => {
			let optionHTML = '';
			for (let i=0; i<_optionArr.length; i++) {
				optionHTML += `<option value=${_optionArr[i].cf_pc_code}>${_optionArr[i].cf_pc_name}</option>`;
			}
			$('.js-select-category').append(optionHTML);
		};

		const getJSONCatgoryGolf = () => {
			getJSONCategory().then((data) => {
				if (data.product_golf_category_list.length) {
					let optionArr = data.product_golf_category_list;
					optionArr = sortKey(optionArr);
					appendCategoryOption(optionArr);
					setSearchFormQueryStr($('.js-select-category'), 'category_id');
				}
			});
		};
		const getJSONCatgoryTennis = () => {
			getJSONCategory().then((data) => {
				if (data.product_tennis_category_list.length) {
					let optionArr = data.product_tennis_category_list;
					optionArr = sortKey(optionArr);
					appendCategoryOption(optionArr);
					setSearchFormQueryStr($('.js-select-category'), 'category_id');
				}
			});
		};
		const getJSONCatgorySofttennis = () => {
			getJSONCategory().then((data) => {
				if (data.product_tennis_category_list.length) {
					let optionArr = data.product_tennis_category_list;
					optionArr = sortKey(optionArr);
					appendCategoryOption(optionArr);
					setSearchFormQueryStr($('.js-select-category'), 'category_id');
				}
			});
		};
		const getJSONCatgoryPadel = () => {
			getJSONCategory().then((data) => {
				if (data.product_padel_category_list.length) {
					let optionArr = data.product_padel_category_list;
					optionArr = sortKey(optionArr);
					appendCategoryOption(optionArr);
					setSearchFormQueryStr($('.js-select-category'), 'category_id');
				}
			});
		};
		const getJSONCatgorySquash = () => {
			getJSONCategory().then((data) => {
				if (data.product_squash_category_list.length) {
					let optionArr = data.product_squash_category_list;
					optionArr = sortKey(optionArr);
					appendCategoryOption(optionArr);
					setSearchFormQueryStr($('.js-select-category'), 'category_id');
				}
			});
		};
		const getJSONCatgoryBadminton = () => {
			getJSONCategory().then((data) => {
				if (data.product_badminton_category_list.length) {
					let optionArr = data.product_badminton_category_list;
					optionArr = sortKey(optionArr);
					appendCategoryOption(optionArr);
					setSearchFormQueryStr($('.js-select-category'), 'category_id');
				}
			});
		};

		if ($('.js-select-category')) {
			if (mapSite === 'golf') {
				getJSONCatgoryGolf();
			} else if (mapSite === 'tennis') {
				getJSONCatgoryTennis();
			} else if (mapSite === 'softtennis') {
				getJSONCatgorySofttennis();
			} else if (mapSite === 'padel') {
				getJSONCatgoryPadel();
			} else if (mapSite === 'squash') {
				getJSONCatgorySquash();
			} else if (mapSite === 'badminton') {
				getJSONCatgoryBadminton();
			}
		}
	};
	// ＜商品検索＞ブランド選択
	const setSelectBrand = () => {
		const appendBrandOption = (_optionArr) => {
			let optionHTML = '';
			for (let i=0; i<_optionArr.length; i++) {
				optionHTML += `<option value=${_optionArr[i].cf_bt_code}>${_optionArr[i].cf_bt_name}</option>`;
			}
			$('.js-select-brand').append(optionHTML);
		};
		const getJSONBrandGolf = () => {
			getJSONBrand().then((data) => {
				if (data.product_golf_brand_list.length) {
					let optionArr = data.product_golf_brand_list;
					optionArr = sortKey(optionArr);
					appendBrandOption(optionArr);
					setSearchFormQueryStr($('.js-select-brand'), 'brand_id');
				}
			});
		};
		const getJSONBrandTennis = () => {
			getJSONBrand().then((data) => {
				if (data.product_tennis_brand_list.length) {
					let optionArr = data.product_tennis_brand_list;
					optionArr = sortKey(optionArr);
					appendBrandOption(optionArr);
					setSearchFormQueryStr($('.js-select-brand'), 'brand_id');
				}
			});
		};
		const getJSONBrandSofttennis = () => {
			getJSONBrand().then((data) => {
				if (data.product_tennis_brand_list.length) {
					let optionArr = data.product_tennis_brand_list;
					optionArr = sortKey(optionArr);
					appendBrandOption(optionArr);
					setSearchFormQueryStr($('.js-select-brand'), 'brand_id');
				}
			});
		};
		const getJSONBrandPadel = () => {
			getJSONBrand().then((data) => {
				if (data.product_padel_brand_list.length) {
					let optionArr = data.product_padel_brand_list;
					optionArr = sortKey(optionArr);
					appendBrandOption(optionArr);
					setSearchFormQueryStr($('.js-select-brand'), 'brand_id');
				}
			});
		};
		const getJSONBrandSquash = () => {
			getJSONBrand().then((data) => {
				if (data.product_squash_brand_list.length) {
					let optionArr = data.product_squash_brand_list;
					optionArr = sortKey(optionArr);
					appendBrandOption(optionArr);
					setSearchFormQueryStr($('.js-select-brand'), 'brand_id');
				}
			});
		};
		const getJSONBrandBadminton = () => {
			getJSONBrand().then((data) => {
				if (data.product_badminton_brand_list.length) {
					let optionArr = data.product_badminton_brand_list;
					optionArr = sortKey(optionArr);
					appendBrandOption(optionArr);
					setSearchFormQueryStr($('.js-select-brand'), 'brand_id');
				}
			});
		};

		if ($('.js-select-brand')) {
			if (mapSite === 'golf') {
				getJSONBrandGolf();
			} else if (mapSite === 'tennis') {
				getJSONBrandTennis();
			} else if (mapSite === 'softtennis') {
				getJSONBrandSofttennis();
			} else if (mapSite === 'padel') {
				getJSONBrandPadel();
			} else if (mapSite === 'squash') {
				getJSONBrandSquash();
			} else if (mapSite === 'badminton') {
				getJSONBrandBadminton();
			}
		}
	};
	// ＜商品検索＞フォームクリア
	const searchFormClear = () => {
		$('.mod-searchForm-clear').on('click', () => {
			$('.js-select-category').val('');
			$('.js-select-brand').val('');
			$('input[name="p_keyword"]').val('');
		});
	};
	// ＜tennis/softtennis 商品検索結果＞商品詳細URL生成
	const setProdURL = (_$ele) => {
		_$ele.find('.mod-prodList-item').each((idx, ele) => {
			const id = $(ele).prev().text();

			getJSONProdDetail(id).then((data) => {
				let dataList;
				if (mapSite === 'tennis' || mapSite === 'softtennis') {
					dataList = data.product_tennis_detail_list;
				} else if (mapSite === 'padel') {
					dataList = data.product_padel_detail_list;
				} else if (mapSite === 'squash') {
					dataList = data.product_squash_detail_list;
				} else if (mapSite === 'badminton') {
					dataList = data.product_badminton_detail_list;
				}
				if (dataList.length) {
					const prodInfo = dataList[0];
					const prodCat = prodInfo.cf_p_category.cf_pc_code;
					let prodCat2;
					let prodURL = `/${mapSite}/products/`;
					let prodURLCat = `${prodCat}/`;

					// 製品のカテゴリを調べる ※3階層まで
					getJSONCategoryAll().then((catData) => {
						let catArr;
						if (mapSite === 'tennis' || mapSite === 'softtennis') {
							catArr = catData.product_tennis_category_list;
						} else if (mapSite === 'padel') {
							catArr = catData.product_padel_category_list;
						} else if (mapSite === 'squash') {
							catArr = catData.product_squash_category_list;
						} else if (mapSite === 'badminton') {
							catArr = catData.product_badminton_category_list;
						}

						// 2階層
						for (let i = 0; i < catArr.length; i++) {
							if (catArr[i].cf_pc_code === prodCat && catArr[i].cf_pc_parent_category !== null) {
								prodCat2 = catArr[i].cf_pc_parent_category.cf_pc_code;
								prodURLCat = `${prodCat2}/${prodURLCat}`;
							}
						}
						// 3階層
						if (prodCat2) {
							for (let i = 0; i < catArr.length; i++) {
								if (catArr[i].cf_pc_code === prodCat2 && catArr[i].cf_pc_parent_category !== null) {
									prodURLCat = `${catArr[i].cf_pc_parent_category.cf_pc_code}/${prodURLCat}`;
								}
							}
						}
						prodURL += `${prodURLCat}${id}.html`;
						$(ele).find('a').attr('href', prodURL);
					});
				}
			});

		});
	};
	// ＜商品詳細＞商品画像スライド
	const setProdImgSlide = () => {
		const arrowPrev = '<button type="button" class="slick-prev"><i class="icon icon-arrow_left"></button>';
		const arrowNext = '<button type="button" class="slick-next"><i class="icon icon-arrow_right"></button>';
		let arr = [];
		$('.prod-detail-slide-item-img').each(function(){
			var imgPath = $(this).children('img').attr('src');
			arr.push(imgPath);
		});

		$('.prod-detail-slide').slick({
			infinite: true,
			speed: 1000,
			autoplaySpeed: 4000,
			slidesToShow: 1,
			arrows: true,
			prevArrow: arrowPrev,
			nextArrow: arrowNext,
			dots: true,
			appendDots: $('.prod-detail-thumb'),
			customPaging: function(slick, index){
				return'<span class="prod-detail-thumb-img"><img src="'+ arr[index] + '" alt="" /></span>';
			}
		});

		// 関連コンテンツ
		$('.relationConts-slide').slick({
			infinite: true,
			speed: 1000,
			autoplaySpeed: 4000,
			slidesToShow: 4,
			arrows: true,
			prevArrow: arrowPrev,
			nextArrow: arrowNext,
			responsive: [
				{
					breakpoint: 1025,
					settings: {
						slidesToShow: 2
					}
				}
			]
		});
	};

	// -- ▲ functions ▲ -------------------------------------------------------------------
	// ------------------------------------------------------------------------------------

	if (mapId) {
		// 製品詳細
		setProdImgSlide();

		if (mapSite === 'tennis' || mapSite === 'softtennis' || mapSite === 'padel' || mapSite === 'squash' || mapSite === 'badminton') {
			setProdURL($('.recommendProd'));
		}

		// 動画がなければブロック削除
		if (!$('.prod-detail-features-movie').find('.mod-youtube').length) {
			$('.prod-detail-features-movie').remove();
		}
		// 機能のコンテンツが1つもなければブロック削除
		if (!$('.prod-detail-features').find('div').length) {
			$('.prod-detail-features').remove();
		}
	} else if (!mapCat || mapCat === '' || mapCat === 'index.html') {
		// 各サイトTOP、製品TOP
		setSelectCategory();
		searchFormClear();
	} else if (mapCat === 'search.html' || mapCat === 'searchresult.html') {
		// 製品検索
		setSelectCategory();
		setSelectBrand();
		searchFormClear();
		if (mapSite === 'tennis' || mapSite === 'softtennis' || mapSite === 'padel' || mapSite === 'squash' || mapSite === 'badminton') {
			setProdURL($('.mod-prodList'));
		}
	} else if (mapCat && (mapSite === 'tennis' || mapSite === 'softtennis' || mapSite === 'padel' || mapSite === 'squash' || mapSite === 'badminton')) {
		setProdURL($('.recommendProd'));

		// カテゴリ一覧（テニスの属性ページ）は一覧もURL作成
		if (mapCat === 'att') {
			setProdURL($('.prodCategory-list'));
		}
	}

	// 製品カテゴリー カレント
	if ($('.prodCategory').length) {
		$('.prodCategory-item-inner').each(function(){
			var btnCat = $(this).children('.mod-a').attr('href');
			if (DUNLOP.va.pathname.indexOf(btnCat) !== -1) {
				$(this).addClass('is-active');
			}
		});
	}

	// ＜テニス／ソフトテニス／パデル／スカッシュ＞ ウエア 2020Spring&Summer(SRIXON)
	if (location.pathname === '/tennis/products/wear/2020ss-srixon/' || location.pathname === '/tennis/products/wear/2020ss-srixon/index.html' ||
		location.pathname === '/softtennis/products/wear/2020ss-srixon/' || location.pathname === '/softtennis/products/wear/2020ss-srixon/index.html' ||
		location.pathname === '/padel/products/wear/2020ss-srixon/' || location.pathname === '/padel/products/wear/2020ss-srixon/index.html' ||
		location.pathname === '/squash/products/wear/2020ss-srixon/' || location.pathname === '/squash/products/wear/2020ss-srixon/index.html') {
		$('.mod-head04-title .mod-head04-title_txt').addClass('color_black');
		$('.mod-head04-img .mod-head04-lead').addClass('color_black');
	}

	// ＜ゴルフ＞ウェアサイトを外部リンクに書き換え
	if (mapSite === 'golf') {
		if ($('.rt_bn_product_category_list').length) {
			$('.rt_bn_product_category_list').each((idx, ele) => {
				if ($(ele).find('a').attr('href') === '/golf/products/wear/') {
					$(ele).find('a').attr('target', '_blank');
					if ($(ele).hasClass('prodCategory-item')) {
						$(ele).find('a').append('<i class="icon icon-blank"></i>');
					} else {
						$(ele).find('.mod-boxLink01-txt').append('<i class="icon icon-blank"></i>');
					}
				}
			});
		}
	}

})(window.jQuery, window.DUNLOP);
